import React, { Fragment } from "react";
import Link from "next/link";
import styles from "@/styles/lustcollection.module.scss";
import { homeLinks } from "@/data/home";
const LustCollection = () => {
  return (
    <Fragment>
      <section className="container">
        <div className={styles.videoResponsive}>
          <div className="row">
            <div
              className={styles.video_cotainer}
              dangerouslySetInnerHTML={{
                __html: homeLinks.lustCollection.youtube_iframe,
              }}
            ></div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <div className="col-md-12 text-center">
          <Link href={homeLinks.lustCollection.link} passHref>
            <a className={styles.btn_get_started}>View All Collections</a>
          </Link>
        </div>
      </div> */}
    </Fragment>
  );
};

export default LustCollection;
